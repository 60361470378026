import { redirect } from 'react-router-dom';
import { isOpenedCheckout, removeOpenedCheckout } from '../store/global';

export const handleRedirect = ({ request }: { request: Request }) => {
  const url = new URL(request.url);
  const orderReferenceId = url.searchParams.get('order_reference_id');

  if (orderReferenceId) {
    return redirect('/rehydrate?orderReferenceId=' + orderReferenceId);
  }

  const reset = url.searchParams.get('reset');
  if (reset) {
    return redirect('/reset');
  }

  const isRehydrating = url.pathname === '/rehydrate';

  if (!isRehydrating && isOpenedCheckout()) {
    removeOpenedCheckout();
    return redirect('/check-status?forward=' + url.pathname);
  }

  return null;
};
