import { ReactNode } from 'react';
import { HandleDeviceId } from '../HandleDeviceId';
import logo from '@/assets/logo.svg';
import { Link } from 'react-router-dom';

export function Layout({ children }: { children: ReactNode }) {
  return (
    <div className="h-full min-h-dvh flex flex-col max-w-screen-sm mx-auto mb-safe">
      {children}
      <div className="flex h-full justify-center items-center sm:mt-auto max-sm:mt-4 mb-4">
        <Link to="https://blossomreads.com/">
          <img src={logo} className="h-8" />
        </Link>
      </div>
      <HandleDeviceId />
    </div>
  );
}
