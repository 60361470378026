import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from './ui/carousel';
import { boyImages, girlImages } from '@/lib/config/exampleImages';

export function Examples({ gender }: { gender: 'boy' | 'girl' }) {
  return (
    <div className="flex flex-col gap-3">
      <h2 className="w-full text-center text-2xl pb-1 mt-4 mb-4">
        What to Expect?
      </h2>
      <Carousel className="select-none">
        <CarouselContent>
          {(gender === 'girl' ? girlImages : boyImages).map((img, i) => (
            <CarouselItem key={i}>
              <img src={img} alt={`book example ${i}`} />
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious type="button" />
        <CarouselNext type="button" />
      </Carousel>
    </div>
  );
}
