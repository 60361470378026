import { Layout } from '@/components/ui/Layout';
import { useRehydrate } from '@/lib/hooks/useRehydrate';
import { removeOpenedCheckout } from '@/lib/store/global';
import { LoaderCircle } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';

export const RehydratePage = () => {
  removeOpenedCheckout(); // useRehydrate will check the payment status
  const [searchParams] = useSearchParams();
  const orderReferenceId = searchParams.get('orderReferenceId');
  useRehydrate(orderReferenceId!);

  return (
    <Layout>
      <div className="grow flex flex-col items-center justify-center">
        <LoaderCircle className="animate-spin mb-2" />
        <p className="text-xl">Loading order</p>
      </div>
    </Layout>
  );
};
