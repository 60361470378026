import { LoaderCircle } from 'lucide-react';
import { Button } from './ui/button';

export function SubmitButton({
  disabled,
  isLoading,
}: {
  disabled?: boolean;
  isLoading?: boolean;
}) {
  return (
    <Button
      type="submit"
      className="max-sm:w-full sm:w-40 px-10 text-xs font-semibold mt-5 uppercase bg-tertiary hover:bg-tertiary/90 active:bg-tertiary text-popover tracking-[2px] relative"
      disabled={disabled || isLoading}
    >
      {isLoading ? <LoaderCircle className="animate-spin" /> : 'Continue'}
    </Button>
  );
}
