type FetchErrorType = 'GENERIC' | 'IMAGE_QUALITY' | 'IMAGE_FILTERS';
export class FetchError extends Error {
  navigateTo: string;
  abortRetry: boolean;
  type: FetchErrorType;

  constructor(
    message: string,
    navigateTo: string,
    abortRetry = false,
    type: FetchErrorType = 'GENERIC',
  ) {
    super(message);
    this.name = 'FetchError';
    this.navigateTo = navigateTo;
    this.abortRetry = abortRetry;
    this.type = type;
  }
}
