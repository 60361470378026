import bannerImage from '@/assets/banners/5.avif';
import { Textarea } from '@/components/ui/textarea';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormHeader } from '@/components/FormHeader';
import { Banner } from '@/components/Banner';
import { occasionSchema } from '@/lib/schemas/occasionSchema';
import { ContinueWhereYouLeftOff } from '@/components/orderForm/continueWhereYouLeft';
import { childDetailsSchema } from '@/lib/schemas/childDetailsSchema';
import { useStepNavigation } from '@/lib/utils/useStepNavigation';
import { SubmitButton } from '@/components/SubmitButton';
import { personalisationNoteSchema } from '@/lib/schemas/personalisationNoteSchema';
import { useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { Stars } from '@/components/icons/stars';
import {
  getDedicationMessageSuggestion,
  preloadDedicationMessageSuggestions,
} from '@/lib/services/suggestions/dedicationMessage/getDedicationMessageSuggestion';
import { Layout } from '@/components/ui/Layout';
import { FormBody } from '@/components/FormBody';
import { themeSchema } from '@/lib/schemas/themeSchema';
import {
  DedicationMessage,
  dedicationMessageSchema,
} from '@/lib/schemas/dedicationMessageSchema';
import { useGlobalStore } from '@/lib/store/global';
import { z } from 'zod';
import { FormField } from '@/components/FormField';
import { useStoriesGeneration } from '@/lib/hooks/useStories';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@/lib/hooks/queryKeys';
import { Trash2 } from 'lucide-react';
import { STEPS } from '@/lib/config/steps';
import { useSubmitFormData } from '@/lib/hooks/useSubmitFormData';

const CURRENT_STEP = STEPS.STEP_5;
const MAX_CHAR_COUNT = 300;

const dedicationMessagePageSchema = childDetailsSchema
  .merge(occasionSchema)
  .merge(themeSchema)
  .merge(personalisationNoteSchema)
  .merge(dedicationMessageSchema);

export function DedicationMessagePage() {
  const { getFormData } = useGlobalStore();
  const data = getFormData(dedicationMessagePageSchema);
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);

  useEffect(() => {
    if (!data) {
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <DedicationMessageForm data={data} /> : null;
}

function DedicationMessageForm({
  data,
}: {
  data: z.infer<typeof dedicationMessagePageSchema>;
}) {
  const { saveFormData, order_reference_id } = useGlobalStore();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<DedicationMessage>({
    resolver: zodResolver(dedicationMessageSchema),
    defaultValues: data,
  });
  useEffect(() => watch(saveFormData).unsubscribe, [watch, saveFormData]);
  const { goToPreviousStep, goToNextStep } = useStepNavigation(CURRENT_STEP);
  const { submitData, isPending } = useSubmitFormData(CURRENT_STEP);
  const [startStoriesGeneration, setStartStoriesGeneration] = useState(false);
  const queryClient = useQueryClient();

  const {
    isStartStoriesGenerationLoading,
    isStartStoriesGenerationSuccess,
    isStartStoriesGenerationError,
  } = useStoriesGeneration({ start: startStoriesGeneration });

  useEffect(() => {
    preloadDedicationMessageSuggestions(order_reference_id, data);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (startStoriesGeneration && isStartStoriesGenerationSuccess) {
      goToNextStep();
      setStartStoriesGeneration(false);
    }
  }, [startStoriesGeneration, isStartStoriesGenerationSuccess, goToNextStep]);

  useEffect(() => {
    if (startStoriesGeneration && isStartStoriesGenerationError) {
      setStartStoriesGeneration(false);
      queryClient.cancelQueries({ queryKey: [queryKeys.GENERATE_STORIES] });
    }
  }, [startStoriesGeneration, isStartStoriesGenerationError, queryClient]);

  const onSubmit = async () => {
    const isFormSubmitted = await submitData();
    if (isFormSubmitted) {
      setStartStoriesGeneration(true);
    }
  };

  const textAreaValue = getValues('book_dedication_message');
  const isTextareaValueClearable = !!textAreaValue;
  const clearTextareaValue = () => {
    setValue('book_dedication_message', '');
  };

  return (
    <Layout>
      <FormHeader
        title={`${data.hero_name}'s Bespoke Adventure`}
        currentStep={CURRENT_STEP}
        onBack={goToPreviousStep}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody>
          <div className="flex-1 lg:max-h-fit">
            <div className="h-fit">
              <div className="relative mx-auto">
                <Banner src={bannerImage} />
              </div>
              <FormField
                label={`Write your own message for ${data.hero_name}`}
                description="Share your dedication or best wishes to be printed in the book."
                inputId="br_order_book_dedication_message"
                optional
              >
                <Textarea
                  {...register('book_dedication_message')}
                  id="br_order_book_dedication_message"
                  placeholder="Your Message"
                  isError={!!errors.book_dedication_message}
                  maxLength={MAX_CHAR_COUNT}
                />
                <div className="w-full flex items-start justify-between gap-2">
                  <Button
                    variant="outline"
                    type="button"
                    className="uppercase border-foreground flex items-center justify-center gap-2"
                    onClick={() => {
                      setValue(
                        'book_dedication_message',
                        getDedicationMessageSuggestion(
                          order_reference_id,
                          data,
                        ),
                      );
                    }}
                  >
                    <Stars />
                    Inspire me
                  </Button>
                  <div className="align-self-end flex items-center gap-2">
                    <p
                      className={`text-xs ${textAreaValue?.length === MAX_CHAR_COUNT ? 'text-primary font-semibold' : ''}`}
                    >
                      {textAreaValue?.length ?? 0} / {MAX_CHAR_COUNT}
                    </p>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="border-foreground"
                      onClick={clearTextareaValue}
                      disabled={!isTextareaValueClearable}
                    >
                      <Trash2 />
                    </Button>
                  </div>
                </div>
              </FormField>
            </div>
          </div>
          <SubmitButton
            isLoading={isPending || isStartStoriesGenerationLoading}
          />
        </FormBody>
      </form>
      <ContinueWhereYouLeftOff />
    </Layout>
  );
}
