import { cn } from '@/lib/utils';
import { Gender } from '@/lib/schemas/genderSchema';

type GenderSelectProps = {
  value?: Gender;
  onChange: (value: Gender) => void;
  isError?: boolean;
};

export const GenderSelect = ({
  value,
  onChange,
  isError,
}: GenderSelectProps) => {
  return (
    <div className="flex flex-row gap-2 w-full">
      <GenderCard
        name="Boy"
        img="/icons/mars.svg"
        onClick={() => onChange('boy')}
        selected={value === 'boy'}
        error={isError}
      />
      <GenderCard
        name="Girl"
        img="/icons/venus.svg"
        onClick={() => onChange('girl')}
        selected={value === 'girl'}
        error={isError}
      />
    </div>
  );
};

const GenderCard = ({
  name,
  img,
  onClick,
  selected,
  error,
}: {
  name: string;
  img: string;
  onClick: () => void;
  selected?: boolean;
  error?: boolean;
}) => {
  return (
    <button
      type="button"
      className={cn(
        'flex flex-row justify-center items-center gap-4 w-full bg-background rounded-md p-2 cursor-pointer text-muted-foreground border-border border-2 hover:bg-border focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
        selected &&
          'border-muted-foreground hover:bg-muted-foreground/20 text-foreground',
        error && 'border-destructive',
      )}
      onClick={onClick}
    >
      <img
        src={img}
        alt=""
        className={cn(
          'object-contain contrast-75 h-6',
          selected && 'contrast-100',
        )}
      />
      <p className="text-2xl">{name}</p>
    </button>
  );
};
