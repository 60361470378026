import { StepEventName, STEPS } from '@/lib/config/steps';
import { useGlobalStore } from '@/lib/store/global';
import { useState } from 'react';
import { useDisplayErrorToast } from '../utils/useDisplayErrorToast';

export const useSubmitFormData = (stepEventName: StepEventName) => {
  const { deviceId, order_reference_id, formData, images } = useGlobalStore();

  const [isPending, setIsPending] = useState(false);
  const [isError, setIsError] = useState(false);

  useDisplayErrorToast({ isError });

  const submitData = async (user_email?: string) => {
    setIsPending(true);
    setIsError(false);

    if (!deviceId) {
      throw new Error('Device ID is not set');
    }

    const url = new URL(`/order-form-event`, import.meta.env.VITE_API_URL);
    url.searchParams.append('event_name', stepEventName);
    url.searchParams.append('device_id', deviceId);
    url.searchParams.append('order_reference_id', order_reference_id);
    if (user_email) {
      url.searchParams.append('user_email', user_email);
    }

    let isSuccess = false;
    try {
      const res = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          book_language: 'en',
          ...(stepEventName !== STEPS.STEP_0 && {
            image_paths:
              images.length > 0
                ? images.map((image) => ({
                    optimized: image.s3_path,
                    original: image.s3_path_original,
                  }))
                : undefined,
            ...formData,
          }),
        }),
      });

      if (res.ok) {
        isSuccess = true;
      } else {
        throw new Error(`Request failed`);
      }
    } catch (error) {
      console.error(`Failed to submit form data in ${stepEventName}:`, error);
      setIsError(true);
    } finally {
      setIsPending(false);
    }
    return isSuccess;
  };

  return { submitData, isPending };
};

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

export const useInitialPageLoadSubmission = () => {
  const { submitData } = useSubmitFormData(STEPS.STEP_0);

  const submitWithRetry = async (deviceId: string, retries = 0) => {
    try {
      await submitData();
    } catch (error) {
      console.error(
        `Failed to submit initial page load event (attempt ${retries + 1}):`,
        error,
      );

      if (retries < MAX_RETRIES) {
        setTimeout(() => submitWithRetry(deviceId, retries + 1), RETRY_DELAY);
      }
    }
  };

  return submitWithRetry;
};
