import { useState } from 'react';
import { z } from 'zod';
import { currency } from '../config/currency';
import { useDisplayErrorToast } from '../utils/useDisplayErrorToast';

export const useRedirectToPayment = () => {
  const [isStripeLoading, setIsStripeLoading] = useState(false);

  const [isError, setIsError] = useState(false);

  useDisplayErrorToast({ isError });

  const redirectToPayment = async (
    includeAudioBook: boolean,
    expressShipping: boolean,
    bookQuantity: number,
    userEmail: string,
    orderReferenceId: string,
  ) => {
    setIsStripeLoading(true);
    setIsError(false);

    const url = new URL(
      '/stripe/stripe-payment-url',
      import.meta.env.VITE_API_URL,
    );
    const products = ['book'];
    if (includeAudioBook) {
      products.push('audio');
    }
    if (expressShipping) {
      products.push('express_shipping');
    }

    try {
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          products: products,
          customer_email: userEmail,
          client_reference_id: orderReferenceId,
          language: 'en',
          testing: import.meta.env.VITE_ENVIRONMENT === 'development',
          book_quantity: bookQuantity,
          currency: currency.toLowerCase(),
        }),
      });

      if (!res.ok) {
        throw new Error('Failed to fetch payment url');
      }

      const { payment_url } = z
        .object({ payment_url: z.string() })
        .parse(await res.json());

      window.location.href = payment_url;
    } catch (error) {
      console.error('Failed to redirect to payment', error);
      setIsError(true);
    } finally {
      setIsStripeLoading(false);
    }
  };

  return { redirectToPayment, isStripeLoading };
};
