import { useToast } from '@/components/ui/use-toast';
import { useEffect } from 'react';

export const useDisplayErrorToast = ({
  isError,
  action = 'sendData',
}: {
  isError: boolean;
  action?: 'sendData' | 'retrieveData';
}) => {
  const { toast } = useToast();

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Something went wrong',
        description: `Failed to ${action === 'sendData' ? 'submit' : 'load'} data. Please try again.`,
        variant: 'destructive',
      });
    }
  }, [isError, action, toast]);
};
