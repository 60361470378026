import { Control, Controller, useForm, UseFormSetValue } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormHeader } from '@/components/FormHeader';
import { ContinueWhereYouLeftOff } from '@/components/orderForm/continueWhereYouLeft';
import React, { useEffect, useState } from 'react';
import { getStepRoute, useStepNavigation } from '@/lib/utils/useStepNavigation';
import { Layout } from '@/components/ui/Layout';
import { FormBody } from '@/components/FormBody';

import { useGlobalStore } from '@/lib/store/global';
import { z } from 'zod';
import { SubmitButton } from '@/components/SubmitButton';
import {
  Similarity,
  similarityOptionalSchema,
  similaritySchema,
} from '@/lib/schemas/similaritySchema';
import {
  getHeroImagesQueryKey,
  getParams,
  startHeroImagesGeneration,
  useHeroImages,
} from '@/lib/hooks/useHeroImages';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Circle,
  CircleCheckBig,
  LoaderCircle,
  ThumbsDown,
  ThumbsUp,
} from 'lucide-react';
import { useStoryImageGeneration } from '@/lib/hooks/useStoryImage';
import { queryKeys } from '@/lib/hooks/queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '@/components/ui/use-toast';
import { getStepNumber, STEPS } from '@/lib/config/steps';
import { useStories } from '@/lib/hooks/useStories';
import { useProgress } from '@/lib/utils/useProgress';
import { ReadStoryDialog } from '@/components/ReadStoryDialog';
import { Button } from '@/components/ui/button';
import { customerDetailsSchema } from '@/lib/schemas/customerDetailsSchema';
import { childDetailsSchema } from '@/lib/schemas/childDetailsSchema';
import { FetchError } from '@/lib/utils/FetchError';
import { useNavigate } from 'react-router-dom';
import { useSubmitFormData } from '@/lib/hooks/useSubmitFormData';

const CURRENT_STEP = STEPS.STEP_8;

const similarityPageSchema = childDetailsSchema
  .merge(customerDetailsSchema)
  .merge(similarityOptionalSchema);

export function SimilarityPage() {
  const { getFormData, images } = useGlobalStore();
  const data = getFormData(similarityPageSchema);
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);

  useEffect(() => {
    if (!data || images.length === 0) {
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <SimilarityForm data={data} /> : null;
}

function SimilarityForm({
  data,
}: {
  data: z.infer<typeof similarityPageSchema>;
}) {
  const { saveFormData, order_reference_id, images } = useGlobalStore();
  const { handleSubmit, watch, formState, control, setValue } =
    useForm<Similarity>({
      resolver: zodResolver(similaritySchema),
      defaultValues: data,
    });
  useEffect(() => watch(saveFormData).unsubscribe, [watch, saveFormData]);
  const similarityNote = watch('illustration_feedback_choice');
  const similarityFeedback = watch('illustration_feedback');

  const { goToPreviousStep, goToNextStep } = useStepNavigation(CURRENT_STEP);
  const { submitData, isPending } = useSubmitFormData(CURRENT_STEP);
  const navigate = useNavigate();

  const {
    data: heroImages,
    isLoading: isHeroImagesLoading,
    isPending: isHeroImagesPending,
    error: heroImagesError,
  } = useHeroImages();

  const isLoadingFinishedWithNoData = !isHeroImagesPending && !heroImages;

  const {
    progress,
    isInProgress: isProgressAnimationInProgress,
    restart: restartProgressAnimation,
  } = useProgress({
    start: isHeroImagesLoading,
    finish: !!heroImages,
    abort: isLoadingFinishedWithNoData,
  });

  const { data: stories } = useStories({ start: false });

  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [startStoryImageGeneration, setStartStoryImageGeneration] =
    useState(false);
  const [isRegenerateHeroImages, setIsRegenerateHeroImages] = useState(false);

  const queryClient = useQueryClient();
  const { toast } = useToast();

  const {
    isStartStoryImageGenerationLoading,
    isStartStoryImageGenerationSuccess,
    isStartHeroImagesGenerationError,
  } = useStoryImageGeneration({ start: startStoryImageGeneration });

  // Simply setting 'defaultValues' didn't save form state for /get-results?task=story_image
  useEffect(() => {
    if (!data.hero_similarity_image_number) {
      setValue('hero_similarity_image_number', 1);
    }
  }, [data.hero_similarity_image_number, setValue]);

  useEffect(() => {
    if (submitAttempted && isStartStoryImageGenerationSuccess) {
      goToNextStep();
    }
  }, [submitAttempted, isStartStoryImageGenerationSuccess, goToNextStep]);

  useEffect(() => {
    if (startStoryImageGeneration && isStartHeroImagesGenerationError) {
      setStartStoryImageGeneration(false);
      queryClient.cancelQueries({ queryKey: [queryKeys.GENERATE_STORY_IMAGE] });
    }
  }, [
    startStoryImageGeneration,
    isStartHeroImagesGenerationError,
    queryClient,
  ]);

  const retryImageGeneration = async () => {
    setIsRegenerateHeroImages(true);
    const queryKey = getHeroImagesQueryKey(
      order_reference_id,
      stories!.title_1,
      stories!.story_1,
      images,
    );
    const params = getParams({
      story_title: stories!.title_1,
      story_text: stories!.story_1,
      hero_gender: data.hero_gender,
      images,
    });
    await queryClient.fetchQuery({
      queryKey: [queryKeys.GENERATE_HERO_IMAGES, queryKey],
      queryFn: () => startHeroImagesGeneration(order_reference_id, params!),
      staleTime: 0,
    });
    queryClient.invalidateQueries({ queryKey: [queryKeys.GET_HERO_IMAGES] });
    setIsRegenerateHeroImages(false);
    restartProgressAnimation();
  };

  const navigateBackToImages = () => {
    navigate(getStepRoute(getStepNumber(STEPS.STEP_6)));
  };

  const onSubmit = async () => {
    if (similarityNote !== 'negative:other') {
      setValue('illustration_feedback', '');
    }

    if (!heroImages) {
      toast({
        title: 'Illustrations In Progress',
        description: `Please wait for illustrations to be created. You will have to pick the best one to continue.`,
        duration: 7000,
      });

      return;
    }

    setSubmitAttempted(true);
    const isFormSubmitted = await submitData();
    if (isFormSubmitted) {
      setStartStoryImageGeneration(true);
    }
  };

  const isEnhanced = Object.keys(heroImages?.enhanced ?? {}).length > 0;
  const imagesMap =
    isEnhanced && heroImages?.enhanced
      ? heroImages.enhanced.image_urls
      : (heroImages?.hero_preview_image_urls_low_res ?? []);

  const getHeroImagesComponent = () => {
    if (isLoadingFinishedWithNoData) {
      return (
        <LoadingFailed
          handleRetry={retryImageGeneration}
          handleBackToImages={navigateBackToImages}
          isButtonLoading={isRegenerateHeroImages}
          error={heroImagesError}
          heroName={data.hero_name}
        />
      );
    }

    if (progress === 99) {
      return <LoadingTimedOut email={data.user_email} />;
    }

    if (!isProgressAnimationInProgress && heroImages) {
      return (
        <>
          <p className="text-xl text-muted-foreground mb-2">
            Select the best matching illustration for the first story. You'll
            see the full story preview in the next step.
          </p>
          <IllustrationSelection
            hero_name={data.hero_name}
            control={control}
            imagesMap={imagesMap}
          />
          <p className="text-sm text-gray-500 w-full">
            🎨 Our artists will polish and enhance the final illustrations for{' '}
            {data.hero_name}'s book.
          </p>
          <IllustrationFeedback
            control={control}
            similarityNote={similarityNote}
            similarityFeedback={similarityFeedback}
            setValue={setValue}
          />
        </>
      );
    }

    if (isProgressAnimationInProgress) {
      return <LoadingIllustrations progress={progress} />;
    }

    return <Skeleton className="h-full" />;
  };

  return (
    <Layout>
      <FormHeader
        title={`First Story & Illustrations`}
        currentStep={CURRENT_STEP}
        onBack={goToPreviousStep}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <h2 className="w-full text-2xl font-medium">
                Story 1 of 6: Your First Tale
              </h2>
              <p className="text-xl text-muted-foreground mb-2">
                Enjoy the first story here. Next, you'll see the full opening
                and a peek at the other five tales!
              </p>
              <div className="bg-card pt-3 pb-1 px-3 rounded-lg border relative flex flex-col">
                <p className="font-semibold text-lg mb-2">{stories?.title_1}</p>
                <p className="overflow-hidden transition-all duration-300 line-clamp-3">
                  {stories?.story_1}
                </p>
                <ReadStoryDialog
                  storyTitle={stories?.title_1 ?? ''}
                  storyText={stories?.story_1 ?? ''}
                />
              </div>
            </div>

            <div className="flex flex-col gap-2 my-4">
              <h2 className="w-full text-2xl font-medium">Illustrations</h2>
              {getHeroImagesComponent()}
            </div>
          </div>

          <SubmitButton
            isLoading={isPending || isStartStoryImageGenerationLoading}
          />
        </FormBody>
      </form>
      {!formState.isSubmitting && !formState.isSubmitted && (
        <ContinueWhereYouLeftOff />
      )}
    </Layout>
  );
}

const LoadingFailed = ({
  handleRetry,
  handleBackToImages,
  isButtonLoading,
  error,
  heroName,
}: {
  handleRetry: () => void;
  handleBackToImages: () => void;
  isButtonLoading: boolean;
  error: FetchError;
  heroName: string;
}) => {
  let text1 = 'Seems like we ran out of paint.';
  let text2 = 'Retry now or come back in 30 minutes.';
  let handleClick = handleRetry;
  let buttonLabel = 'Try Again';

  if (error.type === 'IMAGE_QUALITY') {
    text1 = `We couldn't find two clear photos of ${heroName}.`;
    text2 = 'Please upload different pictures with their face clearly visible.';
    handleClick = handleBackToImages;
    buttonLabel = 'Change Images';
  }

  if (error.type === 'IMAGE_FILTERS') {
    text1 = 'One or more images were flagged as inappropriate.';
    text2 = 'This might be a mistake, but please upload new ones.';
    handleClick = handleBackToImages;
    buttonLabel = 'Change Images';
  }

  return (
    <>
      <p className="text-xl text-muted-foreground mb-2">
        We are drawing illustrations for the first story. You'll see the full
        story preview in the next step.
      </p>
      <div className="aspect-square flex flex-col justify-center items-center rounded-md bg-border gap-2 p-5">
        <p className="text-center text-xl">Ugh, apologies.</p>
        <p className="text-center">
          {text1}
          <br />
          {text2}
        </p>
        <p className="text-center"></p>
        <Button
          variant="outline"
          type="button"
          onClick={handleClick}
          disabled={isButtonLoading}
        >
          {isButtonLoading ? (
            <LoaderCircle className="animate-spin" />
          ) : (
            buttonLabel
          )}
        </Button>
      </div>
    </>
  );
};

const LoadingTimedOut = ({ email }: { email: string }) => (
  <div className="grid grid-cols-2 gap-2 aspect-square relative">
    <Skeleton className="col-span-2" />
    <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center gap-2 p-4">
      <p className="text-center pb-2">
        Thanks for your patience - due to high demand, it's taking a bit longer.
      </p>
      <p className="text-center">
        We'll email your illustrations to
        <br />
        <span className="font-semibold break-words">
          {email.split('@')[0]}
          <wbr />@{email.split('@')[1]}
        </span>
        <br />
        as soon as they're ready.
      </p>
    </div>
  </div>
);

const LoadingIllustrations = ({ progress }: { progress: number }) => (
  <>
    <p className="text-xl text-muted-foreground mb-2">
      We are drawing illustrations for the first story. You'll see the full
      story preview in the next step.
    </p>
    <div className="grid grid-cols-2 gap-2 aspect-square relative rounded-lg bg-card border">
      <Skeleton className="col-span-2 h-full" />
      <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center gap-2 p-4">
        <p className="text-center text-lg">
          {progress < 25
            ? 'Starting up the creative process'
            : progress < 50
              ? 'Sketching out your illustrations'
              : progress < 75
                ? 'Adding colors and details'
                : progress < 100
                  ? 'Finishing touches'
                  : 'Illustrations ready!'}
          {progress < 100 && (
            <span className="font-semibold">
              <span className="animate-[pulse_1s_infinite]">.</span>
              <span className="animate-[pulse_1s_infinite_0.25s]">.</span>
              <span className="animate-[pulse_1s_infinite_0.5s]">.</span>
            </span>
          )}
        </p>
        <div className="flex items-center gap-1">
          <div className="flex items-center justify-between gap-2">
            <div className="relative w-48 h-2 bg-secondary rounded-full">
              <div
                className="absolute h-full bg-primary rounded-full transition-all"
                style={{ width: `${progress}%` }}
              />
            </div>
            <div className="text-lg font-medium flex w-12 justify-end">
              <span className="text-right">{progress}</span>
              <span>%</span>
            </div>
          </div>
        </div>
        <p className="text-center text-muted-foreground">
          Usually this takes less than a minute
        </p>
      </div>
    </div>
  </>
);

type RadioButtonOption = {
  value: string;
  icon: React.ComponentType<{ className?: string }>;
};

type RadioButtonGroupProps = {
  name: 'illustration_feedback_choice';
  control: Control<Similarity>;
  options: RadioButtonOption[];
};

function RadioButtonGroup({ name, control, options }: RadioButtonGroupProps) {
  return (
    <div className="flex w-full gap-2">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            {options.map((option) => {
              const isChecked = field.value?.startsWith(option.value);
              return (
                <label
                  key={option.value}
                  className={`inline-flex items-center justify-center gap-2 px-4 py-3 rounded-md cursor-pointer border-2 flex-1
                  ${
                    isChecked
                      ? 'border-primary bg-primary/10 text-primary hover:bg-primary/20'
                      : 'hover:bg-border'
                  }`}
                >
                  <input
                    type="radio"
                    className="hidden"
                    value={option.value}
                    checked={isChecked}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                  <option.icon className="w-5 h-5 flex-shrink-0" />
                </label>
              );
            })}
          </>
        )}
      />
    </div>
  );
}

type IllustrationFeedbackProps = {
  control: Control<Similarity>;
  similarityNote?: string;
  similarityFeedback?: string;
  setValue: UseFormSetValue<Similarity>;
};

const IllustrationFeedback: React.FC<IllustrationFeedbackProps> = ({
  control,
  similarityNote,
  similarityFeedback,
  setValue,
}) => (
  <div className="flex flex-col gap-4 pt-4">
    <p className="text-xl">Happy with this illustration?</p>
    <RadioButtonGroup
      name="illustration_feedback_choice"
      control={control}
      options={[
        {
          value: 'positive',
          icon: ThumbsUp,
        },
        {
          value: 'negative',
          icon: ThumbsDown,
        },
      ]}
    />
    <div
      className={`w-full transition-all ${similarityNote?.startsWith('negative') ? '' : 'hidden'}`}
    >
      <p className="mb-3 text-lg">What's the main issue?</p>
      <div className="grid grid-cols-2 gap-2">
        {[
          { value: 'negative:likeness', label: 'Likeness' },
          {
            value: 'negative:details_issue',
            label: 'Details Issue',
          },
          {
            value: 'negative:style_and_appeal',
            label: 'Style & Appeal',
          },
          { value: 'negative:other', label: 'Other' },
        ].map((option) => (
          <label key={option.value} className="flex items-center gap-2">
            <input
              type="radio"
              className="w-4 h-4 accent-primary"
              value={option.value}
              checked={similarityNote === option.value}
              onChange={(e) =>
                setValue(
                  'illustration_feedback_choice',
                  e.target.value as Similarity['illustration_feedback_choice'],
                )
              }
            />
            <span>{option.label}</span>
          </label>
        ))}
      </div>
      {similarityNote === 'negative:other' && (
        <textarea
          value={similarityFeedback ?? ''}
          onChange={(e) => setValue('illustration_feedback', e.target.value)}
          placeholder="Please tell us what should be improved..."
          className="w-full min-h-[100px] p-3 rounded-md border outline-none resize-none mt-4"
        />
      )}
    </div>
  </div>
);

type IllustrationSelectionProps = {
  control: Control<Similarity>;
  imagesMap: string[] | undefined;
  hero_name: string;
};

const IllustrationSelection: React.FC<IllustrationSelectionProps> = ({
  control,
  imagesMap,
  hero_name,
}) => (
  <div className="grid grid-cols-2 gap-2 aspect-square">
    <Controller
      name="hero_similarity_image_number"
      control={control}
      render={({ field }) => (
        <>
          {imagesMap!.map((url, i) => (
            <label
              key={i}
              className={`relative overflow-hidden rounded-lg border-2 aspect-square ${
                field.value === i + 1
                  ? 'border-primary'
                  : 'border-border opacity-70'
              }`}
            >
              <input
                name="hero_similarity_image_number"
                type="radio"
                value={i + 1}
                onChange={() => field.onChange(i + 1)}
                className="hidden"
              />
              <img src={url} alt={`${hero_name} image ${i + 1}`} />
              <div className="absolute top-2 left-2 flex justify-center items-center h-7 w-7 rounded-full bg-secondary">
                {field.value === i + 1 ? (
                  <CircleCheckBig className="text-primary" />
                ) : (
                  <Circle className="text-primary" />
                )}
              </div>
            </label>
          ))}
        </>
      )}
    />
  </div>
);
