import { uploadedImagesSchema } from '@/lib/schemas/uploadedImageSchema';

export async function uploadImage(refId: string, file: File) {
  const url = new URL(`/order-form-image-upload`, import.meta.env.VITE_API_URL);
  url.searchParams.append('order_reference_id', refId);

  const formData = new FormData();
  formData.append('files', file);

  const res = await fetch(url, {
    method: 'PUT',
    body: formData,
  });

  if (!res.ok) {
    throw new Error('Failed to upload image');
  }

  const data = uploadedImagesSchema.safeParse(await res.json()).data;

  if (!data) {
    throw new Error('Incorrect image upload response');
  }

  return data;
}
