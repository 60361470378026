import { FormHeader } from '@/components/FormHeader';
import { ContinueWhereYouLeftOff } from '@/components/orderForm/continueWhereYouLeft';
import {
  ChildDetails,
  childDetailsSchema,
} from '@/lib/schemas/childDetailsSchema';
import { useStepNavigation } from '@/lib/utils/useStepNavigation';
import { SubmitButton } from '@/components/SubmitButton';
import { useEffect, useState } from 'react';
import { Layout } from '@/components/ui/Layout';
import { FormBody } from '@/components/FormBody';
import { ImageStep } from '@/components/orderForm/imageStep';
import { useGlobalStore } from '@/lib/store/global';
import { useHeroImagesGeneration } from '@/lib/hooks/useHeroImages';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@/lib/hooks/queryKeys';
import { useToast } from '@/components/ui/use-toast';
import { STEPS } from '@/lib/config/steps';
import { useSubmitFormData } from '@/lib/hooks/useSubmitFormData';

const CURRENT_STEP = STEPS.STEP_6;
export const MINIMUM_PHOTOS = 2;
export const MAXIMUM_PHOTOS = 5;

export function PhotosPage() {
  const { getFormData } = useGlobalStore();
  const data = getFormData(childDetailsSchema);
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);

  useEffect(() => {
    if (!data) {
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <PhotosForm data={data} /> : null;
}

function PhotosForm({ data }: { data: ChildDetails }) {
  const { goToPreviousStep, goToNextStep } = useStepNavigation(CURRENT_STEP);
  const { images } = useGlobalStore();
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [startHeroImagesGeneration, setStartHeroImagesGeneration] =
    useState(false);
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const { submitData, isPending: isPendingSubmit } =
    useSubmitFormData(CURRENT_STEP);
  const {
    isStartHeroImagesGenerationLoading,
    isStartHeroImagesGenerationSuccess,
    isStartHeroImagesGenerationError,
  } = useHeroImagesGeneration({
    start: startHeroImagesGeneration,
  });

  useEffect(() => {
    if (submitAttempted && isStartHeroImagesGenerationSuccess) {
      goToNextStep();
    }
  }, [submitAttempted, isStartHeroImagesGenerationSuccess, goToNextStep]);

  useEffect(() => {
    if (startHeroImagesGeneration && isStartHeroImagesGenerationError) {
      setStartHeroImagesGeneration(false);
      queryClient.cancelQueries({ queryKey: [queryKeys.GENERATE_HERO_IMAGES] });
    }
  }, [
    startHeroImagesGeneration,
    isStartHeroImagesGenerationError,
    queryClient,
  ]);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (images.length < MINIMUM_PHOTOS) {
      const missingImages = MINIMUM_PHOTOS - images.length;

      const getDescription = () => {
        if (missingImages === 1) {
          return 'Please upload 1 more photo to continue.';
        }

        return `Please upload ${missingImages} photos to continue.`;
      };

      toast({
        title: 'Missing Photos',
        description: getDescription(),
        duration: 5000,
      });

      return;
    }

    setSubmitAttempted(true);
    const isFormSubmitted = await submitData();
    if (isFormSubmitted) {
      setStartHeroImagesGeneration(true);
    }
  };

  const isUploadingImages = Object.values(images).some(
    (image) => image.s3_path === image.presigned_url,
  );

  return (
    <Layout>
      <FormHeader
        title={`${data.hero_name}'s Photos`}
        currentStep={CURRENT_STEP}
        onBack={isUploadingImages ? undefined : goToPreviousStep}
      />
      <form onSubmit={onSubmit}>
        <FormBody>
          <div className="flex-1 lg:max-h-fit">
            <ImageStep
              images={images}
              isError={submitAttempted && images.length < MINIMUM_PHOTOS}
              heroName={data.hero_name}
            />
          </div>
          <SubmitButton
            disabled={isUploadingImages}
            isLoading={isPendingSubmit || isStartHeroImagesGenerationLoading}
          />
        </FormBody>
      </form>
      <ContinueWhereYouLeftOff />
    </Layout>
  );
}
