import { useCallback, useState } from 'react';
import { Trash2, LoaderCircle, ImageIcon, Check, X } from 'lucide-react';
import { uploadImage } from '@/lib/utils/orderForm/uploadImages';
import { useGlobalStore } from '@/lib/store/global';
import { UploadedImage } from '@/lib/schemas/uploadedImageSchema';
import { ImageUpload } from '../ImageUpload';
import { MAXIMUM_PHOTOS } from '@/pages/photos';
import goodPhoto from '@/assets/examples/photos/good.avif';
import faceCoveredPhoto from '@/assets/examples/photos/covered.avif';
import blurryPhoto from '@/assets/examples/photos/blurry.avif';
import singlePersonPhoto from '@/assets/examples/photos/single.avif';
import * as Popover from '@radix-ui/react-popover';
import { useToast } from '../ui/use-toast';
import { preloadImage } from '@/lib/utils/preloadImage';

export const ImageStep = ({
  images,
  isError,
  heroName,
}: {
  images: UploadedImage[];
  isError: boolean;
  heroName: string;
}) => {
  const { addImage, removeImage, order_reference_id } = useGlobalStore();
  const { toast } = useToast();
  const [heicImages, setHeicImages] = useState({});

  const onUpload = useCallback(
    (files: FileList) => {
      const remainingSlots = MAXIMUM_PHOTOS - images.length;
      const filesUserAttemptedToUpload = Array.from(files);
      const filesToUpload = filesUserAttemptedToUpload.slice(0, remainingSlots);

      filesToUpload.forEach((file) => {
        const objectUrl = URL.createObjectURL(file);

        if (file.type === 'image/heic') {
          setHeicImages((prev) => ({
            ...prev,
            [objectUrl]: true,
          }));
        }

        addImage({
          presigned_url: objectUrl,
          original_file_name: file.name,
          s3_path: objectUrl,
          s3_path_original: objectUrl,
        });

        uploadImage(order_reference_id, file)
          .then(async (images) => {
            await preloadImage(images[0].presigned_url);
            addImage(images[0]);
          })
          .catch(() => {
            removeImage(file.name);
          });
      });

      if (filesUserAttemptedToUpload.length > remainingSlots) {
        toast({
          title: 'Too many photos',
          description: `You can upload up to ${MAXIMUM_PHOTOS} photos. We only used first ${remainingSlots} of the ${filesUserAttemptedToUpload.length} you selected.`,
          duration: 7000,
        });
      }
    },
    [addImage, order_reference_id, removeImage, images.length, toast],
  );

  return (
    <div className="flex flex-col relative gap-8">
      <p className="text-2xl">
        Please upload two high-quality photos of {heroName} to help us create
        the best illustrations
      </p>

      <div className="grid grid-cols-4 gap-2">
        <GuidelinePhoto
          imageUrl={goodPhoto}
          altText={`Use only clear, well lit photos of ${heroName}`}
          isValid
        />
        <GuidelinePhoto
          imageUrl={faceCoveredPhoto}
          altText="Avoid photos with hats, glasses, etc."
        />
        <GuidelinePhoto
          imageUrl={blurryPhoto}
          altText={`${heroName}'s face needs to be clearly visible and in focus`}
        />
        <GuidelinePhoto
          imageUrl={singlePersonPhoto}
          altText={`${heroName} should be the only person in the photo`}
        />
      </div>

      {images.length < MAXIMUM_PHOTOS && (
        <ImageUpload
          onUpload={onUpload}
          isError={isError}
          imagesUploaded={images.length}
        />
      )}
      <ImageList
        images={images}
        onRemove={removeImage}
        heicImages={heicImages}
      />
      <p className="text-sm text-gray-500 w-full">
        🔒 Photos will be immediately deleted from our servers after
        illustrations are ready.
      </p>
    </div>
  );
};

const ImageList = ({
  images,
  onRemove,
  heicImages,
}: {
  images: UploadedImage[];
  onRemove: (name: string) => void;
  heicImages: { [key: string]: boolean };
}) => (
  <div className="flex flex-col gap-4">
    {images.map((image) => (
      <div key={image.s3_path} className="flex justify-between items-center">
        <div className="max-w-24 aspect-square relative overflow-hidden w-full">
          <Image
            url={image.presigned_url}
            isHeic={heicImages[image.presigned_url]}
          />
        </div>
        <div className="flex items-center p-4 mr-2">
          {image.s3_path === image.presigned_url ? (
            <LoaderCircle className="animate-spin" />
          ) : (
            <button
              className="cursor-pointer disabled:text-muted-foreground"
              onClick={() => onRemove(image.original_file_name)}
            >
              <Trash2 />
            </button>
          )}
        </div>
      </div>
    ))}
    {images.length < 1 && <ImagePlaceholder />}
    {images.length < 2 && <ImagePlaceholder />}
  </div>
);

const ImagePlaceholder = ({ filledBg = false }: { filledBg?: boolean }) => (
  <div className="max-w-24 aspect-square relative overflow-hidden w-full">
    <div
      className={`w-full h-full border-2 border-border rounded-md flex items-center justify-center ${filledBg ? 'bg-border' : ''}`}
    >
      <ImageIcon className="w-8 h-8 text-gray-400" />
    </div>
  </div>
);

const GuidelinePhoto = ({
  altText,
  imageUrl,
  isValid = false,
}: {
  altText: string;
  imageUrl: string;
  isValid?: boolean;
}) => (
  <Popover.Root>
    <Popover.Trigger asChild>
      <div
        className={`h-fit cursor-pointer flex flex-col items-center overflow-hidden rounded-md ${isValid ? 'border-valid bg-valid' : 'border-primary bg-primary'}`}
        style={{ borderWidth: '1px' }}
      >
        <div className="aspect-[1/2]">
          <img
            src={imageUrl}
            alt={altText}
            className="w-full h-full object-cover"
          />
        </div>
        <div className={`w-full flex justify-center items-center h-4 sm:h-6`}>
          {isValid ? (
            <Check className="text-white p-1.5 sm:p-1" />
          ) : (
            <X className="text-white p-1.5 sm:p-1" />
          )}
        </div>
      </div>
    </Popover.Trigger>
    <Popover.Portal>
      <Popover.Content
        side="top"
        sideOffset={2}
        align="center"
        collisionPadding={20}
        className="max-w-64 bg-white relative py-2 px-3 rounded border drop-shadow-lg outline-none"
      >
        <p>{altText}</p>
        <Popover.Arrow className="fill-white" />
      </Popover.Content>
    </Popover.Portal>
  </Popover.Root>
);

const Image = ({ url, isHeic }: { url: string; isHeic: boolean }) => {
  if (isHeic) {
    return <ImagePlaceholder filledBg />;
  }

  return (
    <img
      src={url}
      alt="Uploaded image"
      className="w-full h-full object-cover rounded-md"
    />
  );
};
