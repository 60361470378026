import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createLocalStorageMiddleware } from './localStorageMiddleware';
import { z } from 'zod';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/react';
import { childDetailsOptionalSchema } from '../schemas/childDetailsSchema';
import { occasionOptionalSchema } from '../schemas/occasionSchema';
import { themeSchema } from '../schemas/themeSchema';
import { dedicationMessageSchema } from '../schemas/dedicationMessageSchema';
import { personalisationNoteSchema } from '../schemas/personalisationNoteSchema';
import { customerDetailsOptionalSchema } from '../schemas/customerDetailsSchema';
import { similarityOptionalSchema } from '../schemas/similaritySchema';
import { cartOptionalSchema } from '../schemas/cartSchema';
import {
  UploadedImage,
  uploadedImagesSchema,
} from '../schemas/uploadedImageSchema';

export const orderFormDataOptionalSchema = childDetailsOptionalSchema
  .merge(occasionOptionalSchema)
  .merge(themeSchema)
  .merge(personalisationNoteSchema)
  .merge(dedicationMessageSchema)
  .merge(customerDetailsOptionalSchema)
  .merge(similarityOptionalSchema)
  .merge(cartOptionalSchema);

export type OrderFormDataOptional = z.infer<typeof orderFormDataOptionalSchema>;

interface GlobalStore {
  deviceId: string;
  setDeviceId: (deviceId: string) => void;
  images: UploadedImage[];
  order_reference_id: string;
  addImage: (image: UploadedImage) => void;
  removeImage: (name: string) => void;
  setImages: (images: UploadedImage[]) => void;
  formData: OrderFormDataOptional;
  getFormData: <T>(schema: z.Schema<T>) => T | undefined;
  saveFormData: (data: OrderFormDataOptional) => void;
  lastUpdate: number;
  resetStore: (order_reference_id?: string) => void;
}

const createNewOrderFormState = (order_reference_id = uuidv4()) => {
  const orderFormState = {
    deviceId: '',
    images: [],
    order_reference_id: order_reference_id,
    formData: {},
    lastUpdate: Date.now(),
  };

  Sentry.setTag('order_reference_id', orderFormState.order_reference_id);
  return orderFormState;
};

const orderFormStateSchema = z.object({
  deviceId: z.string(),
  images: uploadedImagesSchema,
  order_reference_id: z.string(),
  formData: orderFormDataOptionalSchema,
  lastUpdate: z.number(),
});

const loadInitialState = () => {
  let orderFormState;
  try {
    orderFormState = orderFormStateSchema.parse(
      JSON.parse(localStorage.getItem('br_order_form') ?? ''),
    );
    Sentry.setTag('order_reference_id', orderFormState.order_reference_id);
  } catch {
    orderFormState = createNewOrderFormState();
  }
  return orderFormState;
};

export const useGlobalStore = create<GlobalStore>()(
  devtools(
    createLocalStorageMiddleware('br_order_form')((set, get) => ({
      ...loadInitialState(),
      setDeviceId: (deviceId) => set(() => ({ deviceId })),
      addImage: (image) =>
        set((state) => {
          const existingImageIndex = state.images.findIndex(
            (img) => img.original_file_name === image.original_file_name,
          );
          if (existingImageIndex > -1) {
            return {
              images: [
                ...state.images.slice(0, existingImageIndex),
                image,
                ...state.images.slice(existingImageIndex + 1),
              ],
            };
          }
          return {
            images: [...state.images, image],
          };
        }),
      setImages: (images) => set(() => ({ images })),
      removeImage: (name) =>
        set((state) => ({
          images: state.images.filter(
            (image) => image.original_file_name !== name,
          ),
        })),
      saveFormData: (update) =>
        set((state) => ({
          formData: { ...state.formData, ...update },
          lastUpdate: Date.now(),
        })),
      getFormData: (schema) => schema.safeParse(get().formData).data,
      resetStore: (order_reference_id) =>
        set(() => ({ ...createNewOrderFormState(order_reference_id) })),
    })),
  ),
);

export const setOpenedCheckout = () => {
  localStorage.setItem('br_opened_checkout', 'true');
};

export const isOpenedCheckout = () => {
  return localStorage.getItem('br_opened_checkout') === 'true';
};

export const removeOpenedCheckout = () => {
  localStorage.removeItem('br_opened_checkout');
};
